export const ADD_UPDATE_CASHPOINT_USER_START = 'ADD_UPDATE_CASHPOINT_USER_START';
export const ADD_UPDATE_CASHPOINT_USER_SUCCESS = 'ADD_UPDATE_CASHPOINT_USER_SUCCESS';
export const ADD_UPDATE_CASHPOINT_USER_FAILURE = 'ADD_UPDATE_CASHPOINT_USER_FAILURE';
export const ADD_UPDATE_CASHPOINT_USER_END = 'ADD_UPDATE_CASHPOINT_USER_END';

// cashpoint sender user
export const ADD_UPDATE_CASHPOINT_SENDER_USER_START = 'ADD_UPDATE_CASHPOINT_SENDER_USER_START';
export const ADD_UPDATE_CASHPOINT_SENDER_USER_SUCCESS = 'ADD_UPDATE_CASHPOINT_SENDER_USER_SUCCESS';
export const ADD_UPDATE_CASHPOINT_SENDER_USER_FAILURE = 'ADD_UPDATE_CASHPOINT_SENDER_USER_FAILURE';
export const ADD_UPDATE_CASHPOINT_SENDER_USER_END = 'ADD_UPDATE_CASHPOINT_SENDER_USER_END';

// cashpoint receiver user
export const ADD_UPDATE_CASHPOINT_RECEIVER_USER_START = 'ADD_UPDATE_CASHPOINT_RECEIVER_USER_START';
export const ADD_UPDATE_CASHPOINT_RECEIVER_USER_SUCCESS = 'ADD_UPDATE_CASHPOINT_RECEIVER_USER_SUCCESS';
export const ADD_UPDATE_CASHPOINT_RECEIVER_USER_FAILURE = 'ADD_UPDATE_CASHPOINT_RECEIVER_USER_FAILURE';
export const ADD_UPDATE_CASHPOINT_RECEIVER_USER_END = 'ADD_UPDATE_CASHPOINT_RECEIVER_USER_END';
