import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';

import './NotFoundPage.scss';

const NotFoundPage: React.FC = (): React.ReactElement => {
  return (
    <div id="NotFoundPage">
      <div className="container">
        <div className="not-found-page-404">
          <h1>
            <span>4</span>
            <span>0</span>
            <span>4</span>
          </h1>
        </div>
        <h2 className="text-light-black">Oops! {global.translate('Page not found')}</h2>
        <h2 className="text-light-black">
          {global.translate('We are sorry, but the page you requested was not found')}
        </h2>
        <div>
          <Button className="go-home-btn" as={Link} to="/" size="big">
            <Icon name="chevron left" className="text-white" />{' '}
            <span className="text-white">{global.translate('Home')}</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
