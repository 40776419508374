import { ReduxDispatchType } from 'types';
import { GET_LANGUAGE_START, GET_LANGUAGE_SUCCESS, GET_LANGUAGE_FAILURE } from 'constants/action-types/users/language';
import apiAction from 'helpers/apiAction';
import { DEFAULT_LANGUAGE } from 'constants/general';
import saveUserLanguageChoice from './saveUserLanguageChoice';

export default (lang?: string) => (dispatchAction: ReduxDispatchType): void | boolean => {
  const language = String(lang || localStorage.language || '').trim();

  if (!language || language === DEFAULT_LANGUAGE) {
    return true;
  }

  return dispatchAction(
    apiAction({
      method: 'post',
      url: '/LoadLanguage',
      data: { Language: language },
      onStart: () => (dispatch: ReduxDispatchType): void =>
        dispatch({
          type: GET_LANGUAGE_START,
        }),
      onSuccess: data => (dispatch: ReduxDispatchType): void => {
        dispatch({
          type: GET_LANGUAGE_SUCCESS,
          payload: { data, preferred: language },
        });
        if (Array.isArray(data) && data.length) {
          localStorage[language] = JSON.stringify(data);
        }
        if (language && localStorage.token) {
          saveUserLanguageChoice(language)(dispatch);
        }
      },
      onFailure: error => (dispatch: ReduxDispatchType): void => {
        return dispatch({
          type: GET_LANGUAGE_FAILURE,
          payload: error,
        });
      },
    }),
  );
};
