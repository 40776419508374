import { useEffect } from 'react';
import { useSelector, TypedUseSelectorHook } from 'react-redux';

import { InitialState } from 'types';
import { RECONNECT } from 'constants/events/common';
import socketIOClient from 'services/socketIO';
import connectUserEvent from './connectUser';

const typedUseSelectorHook: TypedUseSelectorHook<InitialState> = useSelector;

export default (): void => {
  const { currentUser: { data: userData = {}, loading: loadingUser = false } = {} } = typedUseSelectorHook(
    ({ user }) => user,
  );

  const connectUser = (data: { [key: string]: string }): void => {
    if (data.PID) {
      connectUserEvent({
        PID: data.PID,
      });
    }
    if (data.CPID) {
      connectUserEvent({
        PID: data.CPID,
        Country: data.Country,
      });
    }
  };

  useEffect(() => {
    if (!loadingUser && userData && Object.keys(userData).length) {
      connectUser(userData as { [key: string]: string });
      socketIOClient.off(RECONNECT);
      socketIOClient.on(RECONNECT, () => {
        connectUser(userData as { [key: string]: string });
      });
    }

    return (): void => {
      socketIOClient.off(RECONNECT);
    };
  }, [userData.PID, userData.CPID]);
};
