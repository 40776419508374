export default {
  tellersList: {
    error: null,
    loading: false,
    message: '',
    success: false,
    data: [],
  },
  createTeller: {
    error: null,
    loading: false,
    message: '',
    success: false,
    data: {},
  },
  updateTeller: {
    error: null,
    loading: false,
    message: '',
    success: false,
    data: {},
  },
};
