import { ReduxActionType } from 'types';
import IUser from 'types/initial-states/IUser';
import {
  ADD_UPDATE_CASHPOINT_USER_START,
  ADD_UPDATE_CASHPOINT_USER_FAILURE,
  ADD_UPDATE_CASHPOINT_USER_SUCCESS,
} from 'constants/action-types/users/addUpdateCashpointUser';

export default (state: IUser, { type, payload }: ReduxActionType): IUser | null => {
  switch (type) {
    case ADD_UPDATE_CASHPOINT_USER_START:
      return {
        ...state,
        addUpdateCashpointUser: {
          ...state.addUpdateCashpointUser,
          data: {},
          loading: true,
          error: null,
          success: false,
        },
      };
    case ADD_UPDATE_CASHPOINT_USER_FAILURE:
      return {
        ...state,
        addUpdateCashpointUser: {
          ...state.addUpdateCashpointUser,
          data: {},
          error: payload || {},
          loading: false,
          success: false,
        },
      };
    case ADD_UPDATE_CASHPOINT_USER_SUCCESS:
      return {
        ...state,
        addUpdateCashpointUser: {
          ...state.addUpdateCashpointUser,
          data: payload || {},
          error: null,
          loading: false,
          success: true,
        },
      };
    default:
      return null;
  }
};
