/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  GET_LINK_BANK_ACCOUNT_START,
  GET_LINK_BANK_ACCOUNT_SUCCESS,
  GET_LINK_BANK_ACCOUNT_FAILURE,
} from 'constants/action-types/users/getLinkedAccount';

export default (state: any, { type, payload }: any) => {
  switch (type) {
    case GET_LINK_BANK_ACCOUNT_START:
      return {
        ...state,
        getLinkedAccountList: {
          ...state.getLinkedAccountList,
          loading: true,
          error: null,
          success: false,
        },
      };
    case GET_LINK_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        getLinkedAccountList: {
          ...state.getLinkedAccountList,
          data: payload.data,
          loading: false,
          success: payload.success,
        },
      };
    case GET_LINK_BANK_ACCOUNT_FAILURE:
      return {
        ...state,
        getLinkedAccountList: {
          ...state.getLinkedAccountList,
          error: payload.error,
          loading: false,
          success: false,
        },
      };

    default:
      return null;
  }
};
