import initialState from 'redux/initial-states/cashpoint';
import getCashpointHelpers from './getCashpointHelpers';
import createCashpointHelper from './createCashpointHelper';
import updateCashpointHelper from './updateCashpointHelper';

export default (state = initialState, action = {}) => ({
  ...state,
  ...getCashpointHelpers(state, action),
  ...createCashpointHelper(state, action),
  ...updateCashpointHelper(state, action),
});
