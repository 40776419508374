import { LOGIN_START, LOGIN_FAILURE, LOGIN_SUCCESS, LOGIN_END } from 'constants/action-types/users/login';

export default (state, { type, payload }) => {
  switch (type) {
    case LOGIN_START:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          data: {},
        },
        login: {
          ...state.login,
          loading: true,
          error: null,
          success: false,
        },
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        login: {
          ...state.login,
          error: payload,
          loading: false,
          success: false,
        },
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          data: payload,
        },
        login: {
          ...state.login,
          error: null,
          loading: false,
          success: true,
        },
      };
    case LOGIN_END:
      return {
        ...state,
        login: {
          ...state.login,
          loading: false,
        },
      };
    default:
      return null;
  }
};
