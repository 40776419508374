import React from 'react';
import { Button } from 'semantic-ui-react';

type ReloadAppProps = {
  onReload: () => void;
};

const ReloadApp: React.FC<ReloadAppProps> = ({ onReload }: ReloadAppProps): React.ReactElement => {
  return (
    <div className="flex flex-column">
      <span className="medium-text text-light-black medium-padding border-bottom-1 border-light-grey medium-margin-bottom">
        {global.translate('A new version is available, reload and close all tabs')}!
      </span>
      <div className="flex flex-row justify-content-right">
        <Button
          onClick={onReload}
          content={global.translate('Reload')}
          className="text-white-important secondary-important"
          icon="refresh"
          labelPosition="right"
        />
        <Button content={global.translate('Cancel')} />
      </div>
    </div>
  );
};

export default ReloadApp;
