import { SEND_CASH_START, SEND_CASH_FAILURE, SEND_CASH_SUCCESS } from 'constants/action-types/transactions/sendCash';

export default (state, { type, payload }) => {
  switch (type) {
    case SEND_CASH_START:
      return {
        ...state,
        sendCash: {
          ...state.sendCash,
          data: {},
          loading: true,
          error: null,
          success: false,
        },
      };
    case SEND_CASH_FAILURE:
      return {
        ...state,
        sendCash: {
          ...state.sendCash,
          data: {},
          error: payload,
          loading: false,
          success: false,
        },
      };
    case SEND_CASH_SUCCESS:
      return {
        ...state,
        confirmCashpointTransfer: {
          ...state.confirmTransfer,
          data: {},
          error: null,
          loading: false,
          success: false,
        },
        sendCash: {
          ...state.sendCash,
          data: payload,
          error: null,
          loading: false,
          success: true,
        },
      };
    default:
      return null;
  }
};
