import React, { useEffect } from 'react';
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';

import { InitialState } from 'types';
import 'helpers/getUserLocation';
import 'assets/styles/style.scss';
import routes from 'routes';
import getLocation from 'redux/actions/users/getLocation';
import getLanguage from 'redux/actions/users/getLanguage';
import getUserData from 'redux/actions/users/getUserData';
import getSupportedLanguages from 'redux/actions/users/getSupportedLanguages';
import getOperationsCountries from 'redux/actions/transactions/getOperationsCountries';
import useTranslate from 'hooks/useTranslate';
import useInstallApp from 'hooks/useInstallApp';
import handleSocketIOClientEvents from 'services/socketIO/events';
import PageLoader from 'components/common/PageLoader';
import InstallApp from 'components/InstallApp';
import ReloadApp from 'components/ReloadApp';
import AppRoutes from './AppRoutes';
import * as serviceWorker from './serviceWorker';

const typedUseSelectorHook: TypedUseSelectorHook<InitialState> = useSelector;

const App: React.FC = (): JSX.Element => {
  global.translate = useTranslate();
  const dispatch = useDispatch();

  handleSocketIOClientEvents();

  const { showInstallBtn, deferredPrompt, installApp, cancelInstallApp } = useInstallApp();
  const { logout: { loading: logoutLoading = false } = {} } = typedUseSelectorHook(({ user }) => user);

  const reloadPage = (registration: ServiceWorkerRegistration): void => {
    if (registration.waiting?.postMessage) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    }
    setTimeout(() => {
      window.location.reload();
    }, 300);
  };

  const onSWUpdate = (registration: ServiceWorkerRegistration): void => {
    const reloadAppBtn = document.querySelector('.reload-app-toast');
    if (!reloadAppBtn) {
      toast(<ReloadApp onReload={(): void => reloadPage(registration)} />, {
        autoClose: false,
        closeButton: false,
        className: 'reload-app-toast',
      });
    }
  };

  useEffect(() => {
    serviceWorker.register({ onUpdate: onSWUpdate });
    if (localStorage.token) {
      getUserData()(dispatch);
    }
    getLanguage()(dispatch);
    getSupportedLanguages()(dispatch);
    getLocation()(dispatch);
    getOperationsCountries()(dispatch);
  }, []);

  useEffect(() => {
    const installAppBtn = document.querySelector('.install-app-toast');
    if (showInstallBtn && deferredPrompt && !installAppBtn) {
      toast(<InstallApp onInstall={(): void => installApp(deferredPrompt)} onCancel={cancelInstallApp} />, {
        autoClose: false,
        closeButton: false,
        className: 'install-app-toast',
      });
    }
  }, [showInstallBtn, deferredPrompt]);

  return (
    <>
      {logoutLoading ? <PageLoader /> : ''}
      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={7000} />
      <div className="App">
        <AppRoutes routes={routes} />
      </div>
    </>
  );
};

export default App;
