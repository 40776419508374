export const FIND_USER_START = 'FIND_USER_START';
export const FIND_USER_SUCCESS = 'FIND_USER_SUCCESS';
export const FIND_USER_FAILURE = 'FIND_USER_FAILURE';
export const FIND_USER_END = 'FIND_USER_END';

// cashpoint user
export const FIND_CASHPOINT_USER_START = 'FIND_CASHPOINT_USER_START';
export const FIND_CASHPOINT_USER_SUCCESS = 'FIND_CASHPOINT_USER_SUCCESS';
export const FIND_CASHPOINT_USER_FAILURE = 'FIND_CASHPOINT_USER_FAILURE';
export const FIND_CASHPOINT_USER_END = 'FIND_CASHPOINT_USER_END';

// cashpoint sender user
export const FIND_CASHPOINT_SENDER_USER_START = 'FIND_CASHPOINT_SENDER_USER_START';
export const FIND_CASHPOINT_SENDER_USER_SUCCESS = 'FIND_CASHPOINT_SENDER_USER_SUCCESS';
export const FIND_CASHPOINT_SENDER_USER_FAILURE = 'FIND_CASHPOINT_SENDER_USER_FAILURE';
export const FIND_CASHPOINT_SENDER_USER_END = 'FIND_CASHPOINT_SENDER_USER_END';

// cashpoint receiver user
export const FIND_CASHPOINT_RECEIVER_USER_START = 'FIND_CASHPOINT_RECEIVER_USER_START';
export const FIND_CASHPOINT_RECEIVER_USER_SUCCESS = 'FIND_CASHPOINT_RECEIVER_USER_SUCCESS';
export const FIND_CASHPOINT_RECEIVER_USER_FAILURE = 'FIND_CASHPOINT_RECEIVER_USER_FAILURE';
export const FIND_CASHPOINT_RECEIVER_USER_END = 'FIND_CASHPOINT_RECEIVER_USER_END';
