import { SET_CURRENT_WALLET } from 'constants/action-types/wallets/setCurrentWallet';

export default (state, { type, payload }) => {
  switch (type) {
    case SET_CURRENT_WALLET:
      return {
        ...state,
        currentWallet: {
          ...state.currentWallet,
          data: payload,
        },
      };
    default:
      return null;
  }
};
