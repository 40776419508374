import { useState, useEffect } from 'react';
import moment from 'moment';

const ReloadPageButton = (): {
  showInstallBtn: boolean;
  deferredPrompt: any;
  installApp: (deferredPrompt: any) => void;
  cancelInstallApp: () => void;
} => {
  const [showInstallBtn, setShowInstallBtn] = useState<boolean>(false);
  const [deferredPrompt, setDeferredPrompt] = useState<any>(null);

  const toggleInstallBtn = (state: boolean): void => {
    let hourInterval = moment().diff(moment(new Date(Number(localStorage.lastCancelInstallAppDate))), 'hour');

    hourInterval = Number.isInteger(hourInterval) && localStorage.lastCancelInstallAppDate ? hourInterval : 1;

    if (state === false) {
      setShowInstallBtn(false);
    } else if (state === true && hourInterval >= 1) {
      setShowInstallBtn(true);
    }
  };

  const beforeInstall = (e: any): void => {
    e.preventDefault();
    const navigator = window.navigator as any;
    if (window.matchMedia('(display-mode: standalone)').matches || navigator?.standalone === true) {
      toggleInstallBtn(false);
    } else {
      toggleInstallBtn(true);
    }
    setDeferredPrompt(e);
  };

  const afterInstall = (): void => {
    toggleInstallBtn(false);
  };

  const installApp = (beforeInstallPrompt: any): void => {
    if (beforeInstallPrompt) {
      beforeInstallPrompt.prompt();
      beforeInstallPrompt.userChoice.then(() => {
        toggleInstallBtn(false);
        setDeferredPrompt(null);
      });
    }
  };

  const cancelInstallApp = (): void => {
    setDeferredPrompt(null);
    toggleInstallBtn(false);
    localStorage.lastCancelInstallAppDate = Date.now();
  };

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', beforeInstall);
    window.addEventListener('appinstalled', afterInstall);

    return (): void => {
      window.removeEventListener('beforeinstallprompt', beforeInstall);
      window.removeEventListener('appinstalled', afterInstall);
    };
  }, []);

  return {
    showInstallBtn,
    deferredPrompt,
    installApp,
    cancelInstallApp,
  };
};

export default ReloadPageButton;
