import {
  CREATE_CASHPOINT_HELPER_START,
  CREATE_CASHPOINT_HELPER_FAILURE,
  CREATE_CASHPOINT_HELPER_SUCCESS,
} from 'constants/action-types/cashpointHelpers/createCashpointHelper';

export default (state, { type, payload }) => {
  switch (type) {
    case CREATE_CASHPOINT_HELPER_START:
      return {
        ...state,
        createCashpointHelper: {
          ...state.createCashpointHelper,
          loading: true,
          error: null,
          success: false,
        },
      };
    case CREATE_CASHPOINT_HELPER_FAILURE:
      return {
        ...state,
        createCashpointHelper: {
          ...state.createCashpointHelper,
          error: payload,
          loading: false,
          success: false,
        },
      };
    case CREATE_CASHPOINT_HELPER_SUCCESS:
      return {
        ...state,
        createCashpointHelper: {
          ...state.createCashpointHelper,
          data: payload,
          error: null,
          loading: false,
          success: true,
        },
      };
    default:
      return null;
  }
};
