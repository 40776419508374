/* eslint-disable @typescript-eslint/explicit-function-return-type */
import * as actionType from 'constants/action-types/users/bankList';

export default (state: any, { type, payload }: any) => {
  switch (type) {
    case actionType.REQUEST_BANK_START:
      return {
        ...state,
        bankList: {
          ...state.bankList,
          data: [],
          loading: true,
          error: null,
        },
      };
    case actionType.REQUEST_BANK_SUCCESS:
      return {
        ...state,
        bankList: {
          ...state.bankList,
          data: payload.data,
          loading: false,
        },
      };
    case actionType.REQUEST_BANK_FAILURE:
      return {
        ...state,
        bankList: {
          ...state.bankList,
          loading: false,
          error: payload.error,
        },
      };
    default:
      return null;
  }
};
