import React from 'react';
import { Button } from 'semantic-ui-react';

type InstallAppProps = {
  onInstall: () => void;
  onCancel: () => void;
};

const InstallApp: React.FC<InstallAppProps> = ({ onInstall, onCancel }: InstallAppProps): React.ReactElement => {
  return (
    <div className="flex flex-column">
      <span className="medium-text text-light-black medium-padding border-bottom-1 border-light-grey medium-margin-bottom">
        {global.translate('Add to home screen')}?
      </span>
      <div className="flex flex-row justify-content-right">
        <Button
          onClick={onInstall}
          content={global.translate('Install')}
          className="text-white-important secondary-important"
          icon="plus"
          labelPosition="right"
        />
        <Button content={global.translate('Cancel')} onClick={onCancel} />
      </div>
    </div>
  );
};

export default InstallApp;
