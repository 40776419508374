import {
  GET_USER_DATA_START,
  GET_USER_DATA_SUCCESS,
  GET_USER_DATA_FAILURE,
  GET_USER_DATA_END,
} from 'constants/action-types/users/getUserData';
import { MANAGER, TELLER } from 'constants/general';
import getWallets from 'redux/actions/wallets/getWallets';
import apiAction from 'helpers/apiAction';
import checkExpiredTokenError from 'helpers/checkExpiredTokenError';
import logoutHelper from 'helpers/logout';
import changeLanguage from 'redux/actions/users/changeLanguage';
import { GET_WALLETS_SUCCESS } from 'constants/action-types/wallets/getWallets';

const onSuccess = (data, endpoint) => dispatch => {
  if (Array.isArray(data) && data[0]) {
    const user = data[0] || {};
    user.UserType = endpoint === '/GetCPManagerData' ? MANAGER : TELLER;
    localStorage.user = JSON.stringify(user);

    if (user.Language) {
      changeLanguage(user.Language)(dispatch);
    }

    dispatch({ type: GET_USER_DATA_SUCCESS, payload: user });
    if (user.UserType === MANAGER) {
      dispatch({ type: GET_WALLETS_SUCCESS, payload: user?.Wallets ? user.Wallets.reverse() : [] });
    } else {
      getWallets({ CPID: String(user.CPID || '') })(dispatch);
    }
  }
};

const onFailure = error => dispatch => {
  if (!checkExpiredTokenError(error)) {
    return logoutHelper();
  }
  return dispatch({
    type: GET_USER_DATA_FAILURE,
    payload: Array.isArray(error) ? error[0] || {} : error || {},
  });
};

export default (formData?: { [key: string]: string }) => dispatchAction => {
  let body = formData || {};
  let endpoint = '/GetCPManagerData';
  if (body.TPID || localStorage.TPID) {
    endpoint = '/GetTellerData';
    body = {
      ...body,
      PID: body.TPID || String(localStorage.TPID || ''),
    };
  }
  return dispatchAction(
    apiAction({
      method: 'post',
      url: endpoint,
      data: body,
      onStart: () => dispatch => dispatch({ type: GET_USER_DATA_START }),
      onSuccess: data => dispatch => onSuccess(data, endpoint)(dispatch),
      onFailure,
      onEnd: () => dispatch => dispatch({ type: GET_USER_DATA_END }),
    }),
  );
};
