import initialState from 'redux/initial-states/cashpoint';
import getCashpoints from './getCashpoints';
import createCashpoint from './createCashpoint';
import updateCashpoint from './updateCashpoint';

export default (state = initialState, action = {}) => ({
  ...state,
  ...getCashpoints(state, action),
  ...createCashpoint(state, action),
  ...updateCashpoint(state, action),
});
