import {
  GET_OPERATION_COUNTRIES_START,
  GET_OPERATION_COUNTRIES_FAILURE,
  GET_OPERATION_COUNTRIES_SUCCESS,
} from 'constants/action-types/transactions/getOperationsCountries';

export default (state, { type, payload }) => {
  switch (type) {
    case GET_OPERATION_COUNTRIES_START:
      return {
        ...state,
        getOperationsCountries: {
          ...state.getOperationsCountries,
          loading: true,
          error: null,
          data: payload || state.getOperationsCountries.data,
        },
      };
    case GET_OPERATION_COUNTRIES_FAILURE:
      return {
        ...state,
        getOperationsCountries: {
          ...state.getOperationsCountries,
          error: payload,
          success: false,
          loading: false,
        },
      };
    case GET_OPERATION_COUNTRIES_SUCCESS:
      return {
        ...state,
        getOperationsCountries: {
          ...state.getOperationsCountries,
          error: null,
          loading: false,
          success: true,
          data: payload,
        },
      };
    default:
      return null;
  }
};
