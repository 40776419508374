/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  LINK_ACCOUNT_START,
  LINK_ACCOUNT_FAILURE,
  LINK_ACCOUNT_SUCCESS,
} from 'constants/action-types/users/linkAccount';

export default (state: any, { type, payload }: any) => {
  switch (type) {
    case LINK_ACCOUNT_START:
      return {
        ...state,
        linkAccount: {
          ...state.linkAccount,
          loading: true,
          error: null,
        },
      };
    case LINK_ACCOUNT_FAILURE:
      return {
        ...state,
        linkAccount: {
          ...state.linkAccount,
          error: payload,
          loading: false,
        },
      };
    case LINK_ACCOUNT_SUCCESS:
      return {
        ...state,
        linkAccount: {
          ...state.linkAccount,
          error: null,
          loading: false,
          accountLinked: true,
        },
      };
    default:
      return null;
  }
};
