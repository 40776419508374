import ITransaction from 'types/initial-states/ITransaction';
import { ReduxActionType } from 'types';
import { CASH_BACK_START, CASH_BACK_FAILURE, CASH_BACK_SUCCESS } from 'constants/action-types/transactions/cashBack';

export default (state: ITransaction, { type, payload }: ReduxActionType): ITransaction | null => {
  switch (type) {
    case CASH_BACK_START:
      return {
        ...state,
        cashBack: {
          ...state.cashBack,
          data: {},
          loading: true,
          error: null,
          success: false,
        },
      };
    case CASH_BACK_FAILURE:
      return {
        ...state,
        cashBack: {
          ...state.cashBack,
          data: {},
          error: payload || {},
          loading: false,
          success: false,
        },
      };
    case CASH_BACK_SUCCESS:
      return {
        ...state,
        cashBack: {
          ...state.cashBack,
          data: payload || {},
          error: null,
          loading: false,
          success: true,
        },
      };
    default:
      return null;
  }
};
