import { ReduxDispatchType } from 'types';
import {
  SAVE_LANGUAGE_CHOICE_START,
  SAVE_LANGUAGE_CHOICE_SUCCESS,
  SAVE_LANGUAGE_CHOICE_FAILURE,
} from 'constants/action-types/users/language';
import apiAction from 'helpers/apiAction';

export default (lang: string) => (dispatchAction: ReduxDispatchType): void => {
  dispatchAction(
    apiAction({
      method: 'POST',
      url: '/SaveUserLanguageChoice',
      data: { LanguageCode: lang },
      onStart: () => (dispatch): void => {
        dispatch({ type: SAVE_LANGUAGE_CHOICE_START });
      },
      onSuccess: res => (dispatch): void => {
        dispatch({
          type: SAVE_LANGUAGE_CHOICE_SUCCESS,
          payload: Array.isArray(res) ? res[0] || {} : res || {},
        });
      },
      onFailure: err => (dispatch): void => {
        dispatch({
          type: SAVE_LANGUAGE_CHOICE_FAILURE,
          payload: Array.isArray(err) ? err[0] || {} : err || {},
        });
      },
    }),
  );
};
