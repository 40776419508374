export default {
  cashpointHelpersList: {
    error: null,
    loading: false,
    message: '',
    success: false,
    data: [],
  },
  createCashpointHelper: {
    error: null,
    loading: false,
    message: '',
    success: false,
    data: {},
  },
  updateCashpointHelper: {
    error: null,
    loading: false,
    message: '',
    success: false,
    data: {},
  },
};
