export const GET_SUPPORTED_LANGUAGE_START = 'GET_SUPPORTED_LANGUAGE_START';
export const GET_SUPPORTED_LANGUAGE_SUCCESS = 'GET_SUPPORTED_LANGUAGE_SUCCESS';
export const GET_SUPPORTED_LANGUAGE_FAILURE = 'GET_SUPPORTED_LANGUAGE_FAILURE';
export const GET_SUPPORTED_LANGUAGE_END = 'GET_SUPPORTED_LANGUAGE_END';

export const GET_LANGUAGE_START = 'GET_LANGUAGE_START';
export const GET_LANGUAGE_SUCCESS = 'GET_LANGUAGE_SUCCESS';
export const GET_LANGUAGE_FAILURE = 'GET_LANGUAGE_FAILURE';
export const GET_LANGUAGE_END = 'GET_LANGUAGE_END';

export const SAVE_LANGUAGE_CHOICE_START = 'SAVE_LANGUAGE_CHOICE_START';
export const SAVE_LANGUAGE_CHOICE_SUCCESS = 'SAVE_LANGUAGE_CHOICE_SUCCESS';
export const SAVE_LANGUAGE_CHOICE_FAILURE = 'SAVE_LANGUAGE_CHOICE_FAILURE';
export const SAVE_LANGUAGE_CHOICE_END = 'SAVE_LANGUAGE_CHOICE_END';
