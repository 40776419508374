import {
  CONFIRM_CASHPOINT_TRANSFER_START,
  CONFIRM_CASHPOINT_TRANSFER_FAILURE,
  CONFIRM_CASHPOINT_TRANSFER_SUCCESS,
} from 'constants/action-types/transactions/confirmCashpointTransfer';

export default (state, { type, payload }) => {
  switch (type) {
    case CONFIRM_CASHPOINT_TRANSFER_START:
      return {
        ...state,
        addMoney: {
          ...state.addMoney,
          data: {},
          error: payload,
          loading: false,
          success: false,
        },
        confirmCashpointTransfer: {
          ...state.confirmCashpointTransfer,
          data: {},
          loading: true,
          error: null,
          success: false,
        },
      };
    case CONFIRM_CASHPOINT_TRANSFER_FAILURE:
      return {
        ...state,
        addMoney: {
          ...state.addMoney,
          data: {},
          error: payload,
          loading: false,
          success: false,
        },
        confirmCashpointTransfer: {
          ...state.confirmCashpointTransfer,
          data: {},
          error: payload,
          loading: false,
          success: false,
        },
      };
    case CONFIRM_CASHPOINT_TRANSFER_SUCCESS:
      return {
        ...state,
        addMoney: {
          ...state.addMoney,
          data: {},
          error: payload,
          loading: false,
          success: false,
        },
        confirmCashpointTransfer: {
          ...state.confirmCashpointTransfer,
          data: payload,
          error: null,
          loading: false,
          success: true,
        },
      };
    default:
      return null;
  }
};
