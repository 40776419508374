import user from './initial-states/user';
import cashpoint from './initial-states/cashpoint';
import cashpointHelper from './initial-states/cashpointHelper';
import teller from './initial-states/teller';
import wallet from './initial-states/wallet';
import transaction from './initial-states/transaction';
import currency from './initial-states/currency';
import file from './initial-states/file';

export default {
  user,
  cashpoint,
  cashpointHelper,
  teller,
  wallet,
  transaction,
  currency,
  file,
};
