export default {
  cashpointsList: {
    error: null,
    loading: false,
    message: '',
    success: false,
    data: [],
  },
  createCashpoint: {
    error: null,
    loading: false,
    message: '',
    success: false,
    data: {},
  },
  updateCashpoint: {
    error: null,
    loading: false,
    message: '',
    success: false,
    data: {},
  },
  currentCashpointUser: {
    data: {},
    error: null,
    loading: false,
    message: '',
    success: false,
  },
};
