import { VERIFY_OTP_START, VERIFY_OTP_FAILURE, VERIFY_OTP_SUCCESS } from 'constants/action-types/users/verifyOTP';

export default (state, { type, payload }) => {
  switch (type) {
    case VERIFY_OTP_START:
      return {
        ...state,
        verifyOTP: {
          ...state.verifyOTP,
          loading: true,
          error: null,
        },
      };
    case VERIFY_OTP_FAILURE:
      return {
        ...state,
        verifyOTP: {
          ...state.verifyOTP,
          error: payload,
          loading: false,
        },
      };
    case VERIFY_OTP_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          isAuthenticated: true,
        },
        verifyOTP: {
          ...state.verifyOTP,
          error: null,
          loading: false,
          isOTPSent: true,
        },
      };
    default:
      return null;
  }
};
