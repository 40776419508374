import {
  REQUEST_REGISTRATION_START,
  REQUEST_REGISTRATION_FAILURE,
  REQUEST_REGISTRATION_SUCCESS,
} from 'constants/action-types/users/requestRegistration';

export default (state, { type, payload }) => {
  switch (type) {
    case REQUEST_REGISTRATION_START:
      return {
        ...state,
        requestRegistration: {
          ...state.requestRegistration,
          loading: true,
          error: null,
          success: false,
        },
      };
    case REQUEST_REGISTRATION_FAILURE:
      return {
        ...state,
        requestRegistration: {
          ...state.requestRegistration,
          error: payload,
          loading: false,
          success: false,
        },
      };
    case REQUEST_REGISTRATION_SUCCESS:
      return {
        ...state,
        requestRegistration: {
          ...state.requestRegistration,
          error: null,
          loading: false,
          success: true,
        },
      };
    default:
      return null;
  }
};
