import {
  CREATE_CASHPOINT_START,
  CREATE_CASHPOINT_FAILURE,
  CREATE_CASHPOINT_SUCCESS,
} from 'constants/action-types/cashpoints/createCashpoint';

export default (state, { type, payload }) => {
  switch (type) {
    case CREATE_CASHPOINT_START:
      return {
        ...state,
        createCashpoint: {
          ...state.createCashpoint,
          loading: true,
          error: null,
          success: false,
        },
      };
    case CREATE_CASHPOINT_FAILURE:
      return {
        ...state,
        createCashpoint: {
          ...state.createCashpoint,
          error: payload,
          loading: false,
          success: false,
        },
      };
    case CREATE_CASHPOINT_SUCCESS:
      return {
        ...state,
        cashpointsList: {
          ...state.cashpointsList,
          data: Array.isArray(state.cashpointsList.data) ? [payload, ...state.cashpointsList.data] : [payload],
        },
        createCashpoint: {
          ...state.createCashpoint,
          error: null,
          loading: false,
          success: true,
        },
      };
    default:
      return null;
  }
};
