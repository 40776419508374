import {
  CONFIRM_TRANSFER_START,
  CONFIRM_TRANSFER_FAILURE,
  CONFIRM_TRANSFER_SUCCESS,
} from 'constants/action-types/transactions/confirmTransfer';

export default (state, { type, payload }) => {
  switch (type) {
    case CONFIRM_TRANSFER_START:
      return {
        ...state,
        transferFunds: {
          ...state.transferFunds,
          data: {},
          error: payload,
          loading: false,
          success: false,
        },
        confirmTransfer: {
          ...state.confirmTransfer,
          data: {},
          loading: true,
          error: null,
          success: false,
        },
      };
    case CONFIRM_TRANSFER_FAILURE:
      return {
        ...state,
        transferFunds: {
          ...state.transferFunds,
          data: {},
          error: payload,
          loading: false,
          success: false,
        },
        confirmTransfer: {
          ...state.confirmTransfer,
          data: {},
          error: payload,
          loading: false,
          success: false,
        },
      };
    case CONFIRM_TRANSFER_SUCCESS:
      return {
        ...state,
        transferFunds: {
          ...state.transferFunds,
          data: {},
          error: payload,
          loading: false,
          success: false,
        },
        confirmTransfer: {
          ...state.confirmTransfer,
          data: payload,
          error: null,
          loading: false,
          success: true,
        },
      };
    default:
      return null;
  }
};
