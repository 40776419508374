import ITransaction from 'types/initial-states/ITransaction';
import { ReduxActionType } from 'types';
import {
  GET_COMMISSIONS_START,
  GET_COMMISSIONS_FAILURE,
  GET_COMMISSIONS_SUCCESS,
} from 'constants/action-types/transactions/commissions';

export default (state: ITransaction, { type, payload }: ReduxActionType): ITransaction | null => {
  switch (type) {
    case GET_COMMISSIONS_START:
      return {
        ...state,
        commissions: {
          ...state.commissions,
          data: [],
          meta: {},
          loading: true,
          error: null,
          success: false,
        },
      };
    case GET_COMMISSIONS_FAILURE:
      return {
        ...state,
        commissions: {
          ...state.commissions,
          data: [],
          meta: {},
          error: payload || {},
          loading: false,
          success: false,
        },
      };
    case GET_COMMISSIONS_SUCCESS:
      return {
        ...state,
        commissions: {
          ...state.commissions,
          ...payload,
          error: null,
          loading: false,
          success: true,
        },
      };
    default:
      return null;
  }
};
