import { GET_TELLERS_START, GET_TELLERS_FAILURE, GET_TELLERS_SUCCESS } from 'constants/action-types/tellers/getTellers';

export default (state, { type, payload }) => {
  switch (type) {
    case GET_TELLERS_START:
      return {
        ...state,
        tellersList: {
          ...state.tellersList,
          loading: true,
          error: null,
          data: payload || state.tellersList.data,
        },
      };
    case GET_TELLERS_FAILURE:
      return {
        ...state,
        tellersList: {
          ...state.tellersList,
          error: payload,
          loading: false,
        },
      };
    case GET_TELLERS_SUCCESS:
      return {
        ...state,
        tellersList: {
          ...state.tellersList,
          error: null,
          loading: false,
          data: payload,
        },
      };
    default:
      return null;
  }
};
