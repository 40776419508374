import { DEFAULT_LANGUAGE } from 'constants/general';
import getSavedLanguage from 'helpers/getSavedLanguage';

export default {
  language: {
    error: null,
    loading: false,
    message: '',
    preferred: String(localStorage.language || DEFAULT_LANGUAGE).trim(),
    default: String(DEFAULT_LANGUAGE).trim(),
    data: getSavedLanguage(localStorage.language),
    supported: {
      data: [],
      loading: false,
      error: null,
    },
  },
  location: {
    error: null,
    loading: false,
    message: '',
    success: false,
    data: {},
  },
  currentUser: {
    isAuthenticated: false,
    data: {},
    loading: false,
    error: null,
  },
  requestRegistration: {
    error: null,
    loading: false,
    message: '',
    success: false,
    data: {},
  },
  cashpointOwnerRequestData: {
    error: null,
    loading: false,
    message: '',
    success: false,
    data: {},
  },
  completeRegistration: {
    error: null,
    loading: false,
    message: '',
    success: false,
    data: {},
  },
  login: {
    error: null,
    loading: false,
    message: '',
    success: false,
  },
  logout: {
    error: null,
    loading: false,
    message: '',
    success: false,
  },
  sendOTP: {
    error: null,
    loading: false,
    message: '',
    isOTPSent: false,
  },
  verifyOTP: {
    error: null,
    loading: false,
    message: '',
    isOTPValid: false,
  },
  changePasswordPin: {
    error: null,
    loading: false,
    message: '',
    success: false,
    data: {},
  },
  findUser: {
    error: null,
    loading: false,
    message: '',
    success: false,
    data: {},
  },
  findCashpointSenderUser: {
    error: null,
    loading: false,
    message: '',
    success: false,
    data: {},
  },
  findCashpointReceiverUser: {
    error: null,
    loading: false,
    message: '',
    success: false,
    data: {},
  },
  addUpdateCashpointUser: {
    error: null,
    loading: false,
    message: '',
    success: false,
    data: {},
  },
  addUpdateCashpointSenderUser: {
    error: null,
    loading: false,
    message: '',
    success: false,
    data: {},
  },
  addUpdateCashpointReceiverUser: {
    error: null,
    loading: false,
    message: '',
    success: false,
    data: {},
  },
  linkBankAccount: {
    error: null,
    success: false,
    loading: false,
  },
  bankList: {
    error: null,
    loading: false,
    data: [],
  },
  getLinkedAccountList: {
    error: null,
    loading: false,
    data: [],
  },
};
