import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { useSelector, TypedUseSelectorHook } from 'react-redux';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';

import { InitialState } from 'types';
import isAuth from 'helpers/isAuth';
import PageLoader from 'components/common/PageLoader';

type RouteType = {
  exact: boolean;
  name: string;
  path: string;
  component: React.ComponentType<any>;
  protected: boolean;
  children?: RouteType[];
};

type AppRoutesProps = {
  routes: RouteType[];
};

const typedUseSelector: TypedUseSelectorHook<InitialState> = useSelector;

const LazyLoader = (): React.ReactElement => {
  return (
    <div className="xxlarge-margin-top xxlarge-padding-top">
      <Loader active inline="centered" size="massive">
        <span className="text-secondary">{global.translate('Loading', 194)}...</span>
      </Loader>
    </div>
  );
};

const renderRoute = ({
  route,
  isAuthenticated,
  language,
}: {
  route: RouteType;
  isAuthenticated: boolean;
  language: {};
}): JSX.Element => (
  <Route
    key={route.name}
    exact={route.exact}
    path={route.path}
    render={(props): JSX.Element => {
      if (route.protected && !(isAuth() || isAuthenticated)) {
        return <Redirect to="/login" />;
      }
      if (!route.protected && (isAuth() || isAuthenticated)) {
        return <Redirect to="/dashboard" />;
      }

      document.title = `${route.name} | M2U`;
      return (
        <route.component language={language} location={props.location} history={props.history} match={props.match}>
          <Suspense fallback={<LazyLoader />}>
            <Switch>
              {(Array.isArray(route.children) ? route.children : []).map(
                (child: RouteType): JSX.Element => renderRoute({ route: child, isAuthenticated, language }),
              )}
            </Switch>
          </Suspense>
        </route.component>
      );
    }}
  />
);

const AppRoutes = ({ routes }: AppRoutesProps): JSX.Element => {
  const { language = {}, currentUser: { isAuthenticated = false } = {} } = typedUseSelector(({ user }) => user);

  return (
    <Router>
      <Suspense fallback={<PageLoader />}>
        <Switch>{routes.map(route => renderRoute({ route, isAuthenticated, language }))}</Switch>
      </Suspense>
    </Router>
  );
};

AppRoutes.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  match: PropTypes.shape({}),
  routes: PropTypes.instanceOf(Array),
};

AppRoutes.defaultProps = {
  location: {},
  history: {},
  match: {},
  routes: [],
};

export default AppRoutes;
