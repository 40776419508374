/**
 * @param {object} history
 * @returns {void} redirection
 */
export default (history?: { push: (path: string) => void }): void => {
  localStorage.user = '';
  localStorage.token = '';
  localStorage.refreshToken = '';
  localStorage.TPID = '';

  return history && typeof history === 'object' && typeof history.push === 'function'
    ? history.push('/login')
    : window.location.replace('/login');
};
