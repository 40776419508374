import {
  CREATE_TELLER_START,
  CREATE_TELLER_FAILURE,
  CREATE_TELLER_SUCCESS,
} from 'constants/action-types/tellers/createTeller';

export default (state, { type, payload }) => {
  switch (type) {
    case CREATE_TELLER_START:
      return {
        ...state,
        createTeller: {
          ...state.createTeller,
          loading: true,
          error: null,
          success: false,
        },
      };
    case CREATE_TELLER_FAILURE:
      return {
        ...state,
        createTeller: {
          ...state.createTeller,
          error: payload,
          loading: false,
          success: false,
        },
      };
    case CREATE_TELLER_SUCCESS:
      return {
        ...state,
        createTeller: {
          ...state.createTeller,
          error: null,
          loading: false,
          success: true,
        },
      };
    default:
      return null;
  }
};
