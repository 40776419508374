import {
  FIND_CASHPOINT_SENDER_USER_START,
  FIND_CASHPOINT_SENDER_USER_FAILURE,
  FIND_CASHPOINT_SENDER_USER_SUCCESS,
} from 'constants/action-types/users/findUser';

export default (state, { type, payload }) => {
  switch (type) {
    case FIND_CASHPOINT_SENDER_USER_START:
      return {
        ...state,
        findCashpointSenderUser: {
          ...state.findCashpointSenderUser,
          data: {},
          message: '',
          loading: true,
          error: null,
          success: false,
        },
      };
    case FIND_CASHPOINT_SENDER_USER_FAILURE:
      return {
        ...state,
        findCashpointSenderUser: {
          ...state.findCashpointSenderUser,
          ...payload,
          error: payload,
          loading: false,
          success: false,
        },
      };
    case FIND_CASHPOINT_SENDER_USER_SUCCESS:
      return {
        ...state,
        findCashpointSenderUser: {
          ...state.findCashpointSenderUser,
          data: payload,
          message: '',
          error: null,
          loading: false,
          success: true,
        },
      };
    default:
      return null;
  }
};
