import {
  UPDATE_TELLER_START,
  UPDATE_TELLER_FAILURE,
  UPDATE_TELLER_SUCCESS,
} from 'constants/action-types/tellers/updateTeller';

export default (state, { type, payload }) => {
  switch (type) {
    case UPDATE_TELLER_START:
      return {
        ...state,
        updateTeller: {
          ...state.updateTeller,
          loading: true,
          error: null,
          success: false,
        },
      };
    case UPDATE_TELLER_FAILURE:
      return {
        ...state,
        updateTeller: {
          ...state.updateTeller,
          error: payload,
          loading: false,
          success: false,
        },
      };
    case UPDATE_TELLER_SUCCESS:
      return {
        ...state,
        tellersList: {
          ...state.tellersList,
          data: Array.isArray(state.tellersList.data)
            ? state.tellersList.data.map((teller: any) => {
                if (String(payload.TPID || payload.TellerID).toLowerCase() === String(teller.TPID).toLowerCase()) {
                  return {
                    ...teller,
                    ...payload,
                  };
                }
                return teller;
              })
            : [payload],
        },
        updateTeller: {
          ...state.updateTeller,
          error: null,
          loading: false,
          success: true,
        },
      };
    default:
      return null;
  }
};
