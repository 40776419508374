import { ReduxDispatchType } from 'types';
import { GET_LANGUAGE_SUCCESS } from 'constants/action-types/users/language';
import { DEFAULT_LANGUAGE } from 'constants/general';
import getLanguage from 'redux/actions/users/getLanguage';
import getSavedLanguage from 'helpers/getSavedLanguage';
import saveUserLanguageChoice from './saveUserLanguageChoice';

export default (lang: string) => (dispatch: ReduxDispatchType): void | boolean => {
  const language = String(lang).trim();
  localStorage.language = language || localStorage.language;

  const data = getSavedLanguage(language);
  if ((Array.isArray(data) && data.length) || language === DEFAULT_LANGUAGE) {
    if (language && localStorage.token) {
      saveUserLanguageChoice(language)(dispatch);
    }
    return dispatch({
      type: GET_LANGUAGE_SUCCESS,
      payload: { data, preferred: language },
    });
  }

  return getLanguage(language)(dispatch);
};
