import { ReduxDispatchType } from 'types';
import axiosHelper from 'helpers/axiosHelper';
import { API_REQUEST } from 'constants/action-types/apiActions';
import handleAxiosError from 'helpers/handleAxiosError';
import checkExpiredTokenError from 'helpers/checkExpiredTokenError';
import refreshToken from 'redux/actions/users/refreshToken';

export default ({ dispatch, getState }: { dispatch: ReduxDispatchType; getState: () => {} }) => (
  next: ReduxDispatchType,
) => async ({ type = '', payload = {} }): Promise<void | { [key: string]: any }> => {
  if (type !== API_REQUEST) {
    return next({ type, payload });
  }
  try {
    if (typeof payload.onStart === 'function') {
      await payload.onStart()(dispatch);
    }
    const state = getState();
    const currentUser = state.user?.currentUser;
    const { data } = await axiosHelper(payload.httpOptions).request({
      method: payload.method.toLowerCase(),
      url: payload.url,
      data: {
        OwnerPID: currentUser?.data?.OwnerPID || currentUser?.data?.ManagerPID,
        UserPID: currentUser?.data?.OwnerPID || currentUser?.data?.ManagerPID,
        ...payload.data,
      },
      params: payload.queries,
    });

    if (typeof payload.onSuccess === 'function') {
      await payload.onSuccess(data)(dispatch);
    }
  } catch (e) {
    const error = handleAxiosError(e);
    if (checkExpiredTokenError(error) && localStorage.refreshToken) {
      refreshToken({ type, payload })(dispatch);
    } else if (typeof payload.onFailure === 'function') {
      await payload.onFailure(error)(dispatch);
    }
  }
  if (typeof payload.onEnd === 'function') {
    await payload.onEnd()(dispatch);
  }
  return getState();
};
