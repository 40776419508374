import { GET_WALLETS_START, GET_WALLETS_FAILURE, GET_WALLETS_SUCCESS } from 'constants/action-types/wallets/getWallets';

export default (state, { type, payload }) => {
  switch (type) {
    case GET_WALLETS_START:
      return {
        ...state,
        walletsList: {
          ...state.walletsList,
          loading: true,
          error: null,
        },
      };
    case GET_WALLETS_FAILURE:
      return {
        ...state,
        walletsList: {
          ...state.walletsList,
          error: payload,
          loading: false,
        },
      };
    case GET_WALLETS_SUCCESS:
      return {
        ...state,
        currentWallet: {
          ...state.currentWallet,
          data:
            state.currentWallet.data && Object.keys(state.currentWallet.data).length
              ? payload.find(
                  wallet =>
                    String(wallet.AccountNumber).toLowerCase() ===
                    String(state.currentWallet.data.AccountNumber).toLowerCase(),
                ) || payload.find(wallet => String(wallet.Default).toLowerCase() === 'yes')
              : payload.find(wallet => String(wallet.Default).toLowerCase() === 'yes'),
        },
        walletsList: {
          ...state.walletsList,
          error: null,
          loading: false,
          data: payload,
        },
      };
    default:
      return null;
  }
};
