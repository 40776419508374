import { ReduxDispatchType } from 'types';
import {
  GET_OPERATION_COUNTRIES_FAILURE,
  GET_OPERATION_COUNTRIES_START,
  GET_OPERATION_COUNTRIES_SUCCESS,
} from 'constants/action-types/transactions/getOperationsCountries';
import apiAction from 'helpers/apiAction';

export default () => (dispatchAction: ReduxDispatchType): void => {
  dispatchAction(
    apiAction({
      method: 'POST',
      url: '/MNOCountryList',
      onStart: () => async (dispatch): Promise<void> => {
        dispatch({ type: GET_OPERATION_COUNTRIES_START });
      },
      onSuccess: res => async (dispatch): Promise<void> => {
        dispatch({ type: GET_OPERATION_COUNTRIES_SUCCESS, payload: res });
      },
      onFailure: err => async (dispatch): Promise<void> => {
        const error = (Array.isArray(err) ? err[0] || {} : err || {}) as { [key: string]: string };
        dispatch({ type: GET_OPERATION_COUNTRIES_FAILURE, payload: error });
      },
    }),
  );
};
