import {
  GET_CASHPOINT_HELPERS_START,
  GET_CASHPOINT_HELPERS_FAILURE,
  GET_CASHPOINT_HELPERS_SUCCESS,
} from 'constants/action-types/cashpointHelpers/getCashpointHelpers';

export default (state, { type, payload }) => {
  switch (type) {
    case GET_CASHPOINT_HELPERS_START:
      return {
        ...state,
        cashpointHelpersList: {
          ...state.cashpointHelpersList,
          loading: true,
          error: null,
          data: payload || state.cashpointHelpersList.data,
        },
      };
    case GET_CASHPOINT_HELPERS_FAILURE:
      return {
        ...state,
        cashpointHelpersList: {
          ...state.cashpointHelpersList,
          error: payload,
          loading: false,
        },
      };
    case GET_CASHPOINT_HELPERS_SUCCESS:
      return {
        ...state,
        cashpointHelpersList: {
          ...state.cashpointHelpersList,
          error: null,
          loading: false,
          data: payload,
        },
      };
    default:
      return null;
  }
};
