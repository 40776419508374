import * as apiActionTypes from 'constants/action-types/apiActions';
import { ApiAction, ReduxDispatchType, ReduxActionType } from 'types';

const { REACT_APP_LOGIN_NAME, REACT_APP_API_KEY, REACT_APP_ID } = process.env;

export default ({
  url = '',
  method = 'GET',
  data = null,
  queries = null,
  httpOptions = {},
  onStart = () => async (dispatch: ReduxDispatchType): Promise<void> =>
    dispatch({
      type: apiActionTypes.API_REQUEST_START,
      payload: { loading: true },
    }),
  onSuccess = () => async (dispatch: ReduxDispatchType): Promise<void> =>
    dispatch({
      type: apiActionTypes.API_REQUEST_SUCCESS,
      payload: { loading: false },
    }),
  onFailure = () => async (dispatch: ReduxDispatchType): Promise<void> =>
    dispatch({
      type: apiActionTypes.API_REQUEST_FAILURE,
      payload: { loading: false },
    }),
  onEnd = () => async (dispatch: ReduxDispatchType): Promise<void> =>
    dispatch({
      type: apiActionTypes.API_REQUEST_END,
      payload: { loading: false },
    }),
}: ApiAction): ReduxActionType => {
  const urlQueries = queries || {};
  if (queries) {
    Object.keys(queries).forEach(key => {
      urlQueries[key] = typeof urlQueries[key] === 'string' ? urlQueries[key].trim() : urlQueries[key];
      return urlQueries[key] || delete urlQueries[key];
    });
  }

  return {
    type: apiActionTypes.API_REQUEST,
    payload: {
      url,
      method,
      httpOptions,
      data: {
        LoginName: REACT_APP_LOGIN_NAME,
        APIKey: REACT_APP_API_KEY,
        AppID: REACT_APP_ID,
        ...data,
      },
      queries: urlQueries,
      onStart,
      onSuccess,
      onFailure,
      onEnd,
    },
  };
};
