import ITransaction from 'types/initial-states/ITransaction';
import { ReduxActionType } from 'types';
import {
  CHECK_SEND_CASH_TRANSFER_START,
  CHECK_SEND_CASH_TRANSFER_FAILURE,
  CHECK_SEND_CASH_TRANSFER_SUCCESS,
} from 'constants/action-types/transactions/checkSendCashTransfer';

export default (state: ITransaction, { type, payload }: ReduxActionType): ITransaction | null => {
  switch (type) {
    case CHECK_SEND_CASH_TRANSFER_START:
      return {
        ...state,
        checkSendCashTransfer: {
          ...state.checkSendCashTransfer,
          data: {},
          loading: true,
          error: null,
          success: false,
        },
      };
    case CHECK_SEND_CASH_TRANSFER_FAILURE:
      return {
        ...state,
        checkSendCashTransfer: {
          ...state.checkSendCashTransfer,
          data: {},
          error: payload || {},
          loading: false,
          success: false,
        },
      };
    case CHECK_SEND_CASH_TRANSFER_SUCCESS:
      return {
        ...state,
        checkSendCashTransfer: {
          ...state.checkSendCashTransfer,
          data: payload || {},
          error: null,
          loading: false,
          success: true,
        },
      };
    default:
      return null;
  }
};
