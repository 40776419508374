import {
  CHANGE_PASSWORD_PIN_START,
  CHANGE_PASSWORD_PIN_FAILURE,
  CHANGE_PASSWORD_PIN_SUCCESS,
} from 'constants/action-types/users/changePasswordPin';

export default (state, { type, payload }) => {
  switch (type) {
    case CHANGE_PASSWORD_PIN_START:
      return {
        ...state,
        changePasswordPin: {
          ...state.changePasswordPin,
          loading: true,
          error: null,
          success: false,
        },
      };
    case CHANGE_PASSWORD_PIN_FAILURE:
      return {
        ...state,
        changePasswordPin: {
          ...state.changePasswordPin,
          error: payload,
          loading: false,
          success: false,
        },
      };
    case CHANGE_PASSWORD_PIN_SUCCESS:
      return {
        ...state,
        changePasswordPin: {
          ...state.changePasswordPin,
          data: payload,
          error: null,
          loading: false,
          success: true,
        },
      };
    default:
      return null;
  }
};
