import moment from 'moment';

const options = {
  enableHighAccuracy: true,
  timeout: moment.duration({ seconds: 5 }).asMilliseconds(),
  maximumAge: 0,
};

function onSuccess(pos, interval) {
  const crd = pos.coords;
  localStorage.latitude = crd.latitude;
  localStorage.longitude = crd.longitude;
  clearInterval(interval);
}

function onError(err) {
  console.error(`ERROR(${err.code}): ${err.message}`);
}

const interval = setInterval(() => {
  navigator.geolocation.getCurrentPosition(pos => onSuccess(pos, interval), onError, options);
}, moment.duration({ seconds: 5 }).asMilliseconds());
