import { GET_WALLETS_START, GET_WALLETS_SUCCESS, GET_WALLETS_FAILURE } from 'constants/action-types/wallets/getWallets';
import apiAction from 'helpers/apiAction';

export default body => dispatchAction =>
  dispatchAction(
    apiAction({
      method: 'post',
      url: body && typeof body === 'object' && body.CPID ? '/GetCashPointWalletList' : '/GetUserWalletList',
      data: body,
      onStart: () => dispatch =>
        dispatch({
          type: GET_WALLETS_START,
        }),
      onSuccess: data => dispatch => {
        const wallets = Array.isArray(data) ? data : [];
        dispatch({
          type: GET_WALLETS_SUCCESS,
          payload: wallets.reverse(),
        });
      },
      onFailure: err => dispatch => {
        return dispatch({
          type: GET_WALLETS_FAILURE,
          payload: Array.isArray(err) ? err[0] || {} : err || {},
        });
      },
    }),
  );
