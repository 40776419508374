import initialState from 'redux/initial-states/teller';
import getTellers from './getTellers';
import createTeller from './createTeller';
import updateTeller from './updateTeller';

export default (state = initialState, action = {}) => ({
  ...state,
  ...getTellers(state, action),
  ...createTeller(state, action),
  ...updateTeller(state, action),
});
