import axios, { AxiosInstance } from 'axios';

const { REACT_APP_API_URL } = process.env;

export default (httpOptions = {}): AxiosInstance => {
  const { token, baseURL, headers }: { token?: string; baseURL?: string; headers?: {} } = httpOptions;
  const userToken = token || localStorage.token;

  return axios.create({
    baseURL: baseURL || REACT_APP_API_URL,
    headers: {
      ...headers,
      'Content-Type': 'application/json',
      From: userToken || undefined,
    },
  });
};
