import home from './home';
import login from './login';
import verifyOTP from './verifyOTP';
import dashboard from './dashboard';
import registrationRequest from './registrationRequest';
import completeRegistration from './completeRegistration';
import changePasswordPin from './changePasswordPin';
import cashpointsMap from './cashpointsMap';
import notFoundPage from './notFoundPage';

export default [
  home,
  login,
  verifyOTP,
  dashboard,
  registrationRequest,
  completeRegistration,
  changePasswordPin,
  cashpointsMap,
  notFoundPage,
];
