import {
  GET_LOCATION_START,
  GET_LOCATION_SUCCESS,
  GET_LOCATION_FAILURE,
} from 'constants/action-types/users/getLocation';

import apiAction from 'helpers/apiAction';

export default () => dispatchAction =>
  dispatchAction(
    apiAction({
      method: 'post',
      url: '/GetLocationData',
      data: {},
      onStart: () => dispatch =>
        dispatch({
          type: GET_LOCATION_START,
        }),
      onSuccess: res => dispatch => {
        return dispatch({
          type: GET_LOCATION_SUCCESS,
          payload: Array.isArray(res) ? res[0] || {} : res || {},
        });
      },
      onFailure: err => dispatch => {
        return dispatch({
          type: GET_LOCATION_FAILURE,
          payload: Array.isArray(err) ? err[0] || {} : err || {},
        });
      },
    }),
  );
