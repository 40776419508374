import ITransaction from 'types/initial-states/ITransaction';
import { ReduxActionType } from 'types';
import {
  GET_TRANSACTIONS_GRAPH_DATA_START,
  GET_TRANSACTIONS_GRAPH_DATA_FAILURE,
  GET_TRANSACTIONS_GRAPH_DATA_SUCCESS,
} from 'constants/action-types/transactions/getTransactionsGraphData';

export default (state: ITransaction, { type, payload }: ReduxActionType): ITransaction | null => {
  switch (type) {
    case GET_TRANSACTIONS_GRAPH_DATA_START:
      return {
        ...state,
        getTransactionsGraphData: {
          ...state.getTransactionsGraphData,
          data: [],
          loading: true,
          error: null,
          success: false,
        },
      };
    case GET_TRANSACTIONS_GRAPH_DATA_FAILURE:
      return {
        ...state,
        getTransactionsGraphData: {
          ...state.getTransactionsGraphData,
          data: [],
          error: payload || {},
          loading: false,
          success: false,
        },
      };
    case GET_TRANSACTIONS_GRAPH_DATA_SUCCESS:
      return {
        ...state,
        getTransactionsGraphData: {
          ...state.getTransactionsGraphData,
          data: (payload || []) as [],
          error: null,
          loading: false,
          success: true,
        },
      };
    default:
      return null;
  }
};
