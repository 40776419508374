import {
  ADD_UPDATE_CASHPOINT_RECEIVER_USER_START,
  ADD_UPDATE_CASHPOINT_RECEIVER_USER_FAILURE,
  ADD_UPDATE_CASHPOINT_RECEIVER_USER_SUCCESS,
} from 'constants/action-types/users/addUpdateCashpointUser';

export default (state, { type, payload }) => {
  switch (type) {
    case ADD_UPDATE_CASHPOINT_RECEIVER_USER_START:
      return {
        ...state,
        addUpdateCashpointReceiverUser: {
          ...state.addUpdateCashpointReceiverUser,
          data: {},
          loading: true,
          error: null,
          success: false,
        },
      };
    case ADD_UPDATE_CASHPOINT_RECEIVER_USER_FAILURE:
      return {
        ...state,
        addUpdateCashpointReceiverUser: {
          ...state.addUpdateCashpointReceiverUser,
          data: {},
          error: payload,
          loading: false,
          success: false,
        },
      };
    case ADD_UPDATE_CASHPOINT_RECEIVER_USER_SUCCESS:
      return {
        ...state,
        addUpdateCashpointReceiverUser: {
          ...state.addUpdateCashpointReceiverUser,
          data: payload,
          error: null,
          loading: false,
          success: true,
        },
      };
    default:
      return null;
  }
};
