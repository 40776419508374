import ITransaction from 'types/initial-states/ITransaction';
import { ReduxActionType } from 'types';
import {
  CANCEL_TRANSACTION_START,
  CANCEL_TRANSACTION_FAILURE,
  CANCEL_TRANSACTION_SUCCESS,
} from 'constants/action-types/transactions/cancelTransaction';

export default (state: ITransaction, { type, payload }: ReduxActionType): ITransaction | null => {
  switch (type) {
    case CANCEL_TRANSACTION_START:
      return {
        ...state,
        cancelTransaction: {
          ...state.cancelTransaction,
          data: {},
          loading: true,
          error: null,
          success: false,
        },
      };
    case CANCEL_TRANSACTION_FAILURE:
      return {
        ...state,
        cancelTransaction: {
          ...state.cancelTransaction,
          data: {},
          error: payload || {},
          loading: false,
          success: false,
        },
      };
    case CANCEL_TRANSACTION_SUCCESS:
      return {
        ...state,
        cancelTransaction: {
          ...state.cancelTransaction,
          data: payload || {},
          error: null,
          loading: false,
          success: true,
        },
      };
    default:
      return null;
  }
};
