import {
  GET_LOCATION_START,
  GET_LOCATION_FAILURE,
  GET_LOCATION_SUCCESS,
} from 'constants/action-types/users/getLocation';

export default (state, { type, payload }) => {
  switch (type) {
    case GET_LOCATION_START:
      return {
        ...state,
        location: {
          ...state.location,
          loading: true,
          error: null,
        },
      };
    case GET_LOCATION_FAILURE:
      return {
        ...state,
        location: {
          ...state.location,
          error: payload,
          loading: false,
        },
      };
    case GET_LOCATION_SUCCESS:
      return {
        ...state,
        location: {
          ...state.location,
          data: payload,
          error: null,
          loading: false,
        },
      };
    default:
      return null;
  }
};
