import {
  UPDATE_CASHPOINT_START,
  UPDATE_CASHPOINT_FAILURE,
  UPDATE_CASHPOINT_SUCCESS,
} from 'constants/action-types/cashpoints/updateCashpoint';

export default (state, { type, payload }) => {
  switch (type) {
    case UPDATE_CASHPOINT_START:
      return {
        ...state,
        updateCashpoint: {
          ...state.updateCashpoint,
          loading: true,
          error: null,
          success: false,
        },
      };
    case UPDATE_CASHPOINT_FAILURE:
      return {
        ...state,
        updateCashpoint: {
          ...state.updateCashpoint,
          error: payload,
          loading: false,
          success: false,
        },
      };
    case UPDATE_CASHPOINT_SUCCESS:
      return {
        ...state,
        cashpointsList: {
          ...state.cashpointsList,
          data: Array.isArray(state.cashpointsList.data)
            ? state.cashpointsList.data.map(cashpoint => {
                if (String(payload.PID).toLowerCase() === String(cashpoint.CPID).toLowerCase()) {
                  return {
                    ...cashpoint,
                    ...payload,
                  };
                }
                return cashpoint;
              })
            : [payload],
        },
        updateCashpoint: {
          ...state.updateCashpoint,
          error: null,
          loading: false,
          success: true,
        },
      };
    default:
      return null;
  }
};
