export default {
  currentWallet: {
    data: {},
    loading: false,
    error: null,
    message: '',
  },
  walletsList: {
    error: null,
    loading: false,
    message: '',
    success: false,
    data: [],
  },
  createWallet: {
    error: null,
    loading: false,
    message: '',
    success: false,
    data: {},
  },
};
