import { UPLOAD_FILE_START, UPLOAD_FILE_FAILURE, UPLOAD_FILE_SUCCESS } from 'constants/action-types/files/uploadFile';

export default (state, { type, payload }) => {
  switch (type) {
    case UPLOAD_FILE_START:
      return {
        ...state,
        uploadFile: {
          ...state.uploadFile,
          data: {},
          loading: true,
          error: null,
          success: false,
        },
      };
    case UPLOAD_FILE_FAILURE:
      return {
        ...state,
        uploadFile: {
          ...state.uploadFile,
          data: {},
          error: payload,
          loading: false,
          success: false,
        },
      };
    case UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        uploadFile: {
          ...state.uploadFile,
          data: payload,
          error: null,
          loading: false,
          success: true,
        },
      };
    default:
      return null;
  }
};
