import { ReduxActionType } from 'types';
import IUser from 'types/initial-states/IUser';
import userInitialState from 'redux/initial-states/user';
import bankList from './getBankList';
import getLocation from './getLocation';
import requestRegistration from './requestRegistration';
import getCashpointOwnerRequestData from './getCashpointOwnerRequestData';
import completeRegistration from './completeRegistration';
import login from './login';
import logout from './logout';
import getUserData from './getUserData';
import sendOTP from './sendOTP';
import verifyOTP from './verifyOTP';
import getSupportedLanguages from './getSupportedLanguages';
import getLanguage from './getLanguage';
import changePasswordPin from './changePasswordPin';
import findUser from './findUser';
import findCashpointSenderUser from './findCashpointSenderUser';
import findCashpointReceiverUser from './findCashpointReceiverUser';
import addUpdateCashpointUser from './addUpdateCashpointUser';
import addUpdateCashpointSenderUser from './addUpdateCashpointSenderUser';
import addUpdateCashpointReceiverUser from './addUpdateCashpointReceiverUser';
import linkAccount from './linkAccount';
import linkBankAccount from './linkBankAccount';
import getLinkedAccountList from './getLinkedAccountList';

export default (initialState: IUser, apiAction: ReduxActionType): IUser => {
  const action = apiAction || {};
  const state = initialState || userInitialState;
  return {
    ...state,
    ...getLocation(state, action),
    ...requestRegistration(state, action),
    ...getCashpointOwnerRequestData(state, action),
    ...completeRegistration(state, action),
    ...login(state, action),
    ...logout(state, action),
    ...getUserData(state, action),
    ...sendOTP(state, action),
    ...verifyOTP(state, action),
    ...getSupportedLanguages(state, action),
    ...getLanguage(state, action),
    ...changePasswordPin(state, action),
    ...findUser(state, action),
    ...findCashpointSenderUser(state, action),
    ...findCashpointReceiverUser(state, action),
    ...addUpdateCashpointUser(state, action),
    ...addUpdateCashpointSenderUser(state, action),
    ...addUpdateCashpointReceiverUser(state, action),
    ...linkAccount(state, action),
    ...linkBankAccount(state, action),
    ...bankList(state, action),
    ...getLinkedAccountList(state, action),
  };
};
