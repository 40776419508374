import { ADD_MONEY_START, ADD_MONEY_FAILURE, ADD_MONEY_SUCCESS } from 'constants/action-types/transactions/addMoney';

export default (state, { type, payload }) => {
  switch (type) {
    case ADD_MONEY_START:
      return {
        ...state,
        addMoney: {
          ...state.addMoney,
          data: {},
          loading: true,
          error: null,
          success: false,
        },
      };
    case ADD_MONEY_FAILURE:
      return {
        ...state,
        addMoney: {
          ...state.addMoney,
          data: {},
          error: payload,
          loading: false,
          success: false,
        },
      };
    case ADD_MONEY_SUCCESS:
      return {
        ...state,
        confirmCashpointTransfer: {
          ...state.confirmTransfer,
          data: {},
          error: null,
          loading: false,
          success: false,
        },
        addMoney: {
          ...state.addMoney,
          data: payload,
          error: null,
          loading: false,
          success: true,
        },
      };
    default:
      return null;
  }
};
