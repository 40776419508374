import {
  UPDATE_CASHPOINT_HELPER_START,
  UPDATE_CASHPOINT_HELPER_FAILURE,
  UPDATE_CASHPOINT_HELPER_SUCCESS,
} from 'constants/action-types/cashpointHelpers/updateCashpointHelper';

export default (state, { type, payload }) => {
  switch (type) {
    case UPDATE_CASHPOINT_HELPER_START:
      return {
        ...state,
        updateCashpointHelper: {
          ...state.updateCashpointHelper,
          loading: true,
          error: null,
          success: false,
        },
      };
    case UPDATE_CASHPOINT_HELPER_FAILURE:
      return {
        ...state,
        updateCashpointHelper: {
          ...state.updateCashpointHelper,
          error: payload,
          loading: false,
          success: false,
        },
      };
    case UPDATE_CASHPOINT_HELPER_SUCCESS:
      return {
        ...state,
        cashpointHelpersList: {
          ...state.cashpointHelpersList,
          data: Array.isArray(state.cashpointHelpersList.data)
            ? state.cashpointHelpersList.data.map((cashpointHelper: any) => {
                if (String(payload.HelperPID).toLowerCase() === String(cashpointHelper.HelperPID).toLowerCase()) {
                  return {
                    ...cashpointHelper,
                    ...payload,
                  };
                }
                return cashpointHelper;
              })
            : [payload],
        },
        updateCashpointHelper: {
          ...state.updateCashpointHelper,
          error: null,
          loading: false,
          success: true,
        },
      };
    default:
      return null;
  }
};
