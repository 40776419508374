import user from 'redux/reducers/users';
import cashpoint from 'redux/reducers/cashpoints';
import cashpointHelper from 'redux/reducers/cashpointHelpers';
import teller from 'redux/reducers/tellers';
import wallet from 'redux/reducers/wallets';
import transaction from 'redux/reducers/transactions';
import currency from 'redux/reducers/currencies';
import file from 'redux/reducers/files';

export default {
  user,
  cashpoint,
  cashpointHelper,
  teller,
  wallet,
  transaction,
  currency,
  file,
};
